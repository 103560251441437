var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',{attrs:{"loading":_vm.loadingTree}},[_c('v-card-title',[_c('h3',{staticClass:"ml-3",staticStyle:{"color":"#e33354"}},[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-3 mt-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleNew}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.PAGE"))+" ")]):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},on:{"input":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-btn-toggle',{staticClass:"mt-4 float-right",attrs:{"dense":"","mandatory":""}},[_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeViewType.apply(null, arguments)}}},[(_vm.viewType == 'tree')?_c('v-icon',[_vm._v(" mdi-view-headline ")]):_c('v-icon',[_vm._v(" mdi-file-tree")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('PageModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm}}),(_vm.viewType == 'tree')?_c('v-treeview',{attrs:{"open-all":"","items":_vm.computedPageCollection,"item-key":"id","dense":"","item-children":"children","hoverable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(
                  typeof item.translations[_vm.appLocale] != 'undefined' &&
                  item.translations[_vm.appLocale]
                )?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"label",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClickItem(item)}}},[_vm._v(" "+_vm._s(_vm.$helpers.getTranslated(item.translations).name)+" ")])]}},{key:"append",fn:function(ref){
                var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,false,534360635)}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pageCollection,"search":_vm.search,"sort-by":['id'],"sort-desc":"true","loading":_vm.loadingTable,"footer-props":{
              'items-per-page-options': [100],
            }},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('PageModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.translated",fn:function(ref){
            var item = ref.item;
return [(item.translated)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("STATUSES." + _vm.statuses.pages.page[item.status].value))+" ")]}},{key:"item.parentName",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getParentName(item))+" ")])]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updated_at).toLocaleString("hu-HU", { timeZone: "UTC", }))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }